import { template as template_208d3c7287b7419a8e9cca78eeecc968 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import { i18n } from "discourse-i18n";
const UserSummarySection = template_208d3c7287b7419a8e9cca78eeecc968(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
