import { template as template_df8c5a42cc064e339a9182e131b31139 } from "@ember/template-compiler";
const WelcomeHeader = template_df8c5a42cc064e339a9182e131b31139(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
