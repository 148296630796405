import { template as template_bb034b0e7e5f4463a428a5c8aadf1f2b } from "@ember/template-compiler";
const SidebarSectionMessage = template_bb034b0e7e5f4463a428a5c8aadf1f2b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
