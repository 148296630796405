import { template as template_13d88194a1e04ff18987f12040e251fc } from "@ember/template-compiler";
const FKText = template_13d88194a1e04ff18987f12040e251fc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
